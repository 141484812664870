import { TiptapControl } from "@mzara/component"
import { useKanbanTiptapSuggestions } from "views/kanban/views/details/hooks/useKanbanTiptapSuggestions"

export const AppointmentTiptapEditor = (props: AppointmentTiptapEditorProps) => {


    const suggestions = useKanbanTiptapSuggestions(props.boardId)

    return (
        <TiptapControl
            readonly
            value={props.value}
            suggestions={suggestions}
            />
    )
}

export type AppointmentTiptapEditorProps = {
    boardId: number
    value: string
}
